<template>
<v-layout column>
    <v-flex>
        <h2>
            <v-icon class="mr-2">mdi-chart-areaspline</v-icon>Sales Report Summary
        </h2>
    </v-flex>
    <v-flex class="mx-6">
        <alert-message v-if="msg" :error="msg"></alert-message>
    </v-flex>
    <v-container>
        <v-flex>
            <v-layout align-center wrap>
                <v-flex xs4 sm3 class="ml-3">
                    <v-select v-model="span" :items="spanItems" title="label" itemText="label" value="value" label="Sales report for *"></v-select>
                </v-flex>
                <v-flex xs4 sm3 class="ml-3">
                    <input-date @clear="startDateCleared=true" v-model="startDate" label="Start Date"></input-date>
                </v-flex>
                <v-flex xs4 sm3 class=" ml-3 mr-5">
                    <input-date @clear="endDateCleared=true" v-model="endDate" label="End Date"></input-date>
                </v-flex>
                <v-flex>
                    <v-btn mb-6 outlined :style="themeInverted" @click="generateReport()">Generate</v-btn>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex>
            <v-container>
                <v-layout v-if="items.length>0" column>
                    <v-flex text-center>
                        <h2 class="report-heading">Sales Report Summary from - {{reportStartDate}} to -{{reportEndDate}}</h2>
                    </v-flex>
                    <v-flex text-right>
                        <export-to-csv :data="items" :fileName="`${span}-sales-report-form-${reportStartDate}-to-${reportEndDate}`"></export-to-csv>
                        <export-to-xls :data="items" :fileName="`${span}-sales-report-form-${reportStartDate}-to-${reportEndDate}`"></export-to-xls>
                    </v-flex>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>Label</th>
                                <th class="text-right">Sales</th>
                                <th class="text-right">Growth</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items.slice().reverse()" :key="item.code">
                                <td>{{item.label}}</td>
                                <td class="text-right">₹ {{item.value}}/-</td>
                                <td class="text-right" v-if="item.growth>0">{{item.growth.toFixed(0)}}% <v-icon style="color:green" small>mdi-arrow-up</v-icon></td>
                                <td class="text-right" v-else-if="item.growth<0">{{item.growth.toFixed(0)}}% <v-icon style="color:red" small>mdi-arrow-down</v-icon></td>
                                <td class="text-right" v-else><v-icon style="color:light grey">mdi-minus</v-icon></td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-layout>
                <v-flex text-center>
                    <progress-bar :show="loading"></progress-bar>
                </v-flex>
            </v-container>
        </v-flex>
    </v-container>
</v-layout>
</template>

<script>
import appConstants from '@/utils/appConstants'
import ExportToCsv from '@/components/ExportToCsv'
import ExportToXls from '@/components/ExportToXls'
export default {
    components: {
        ExportToCsv,
        ExportToXls
    },
    data() {
        return {
            startDate: null,
            endDate: null,
            resportStartDate:'',
            reportEndDate:'',
            startDateCleared:false,
            endDateCleared:false,
            items: [],
            span: 'monthly',
            spanItems: [
                { label: 'Week', value: 'weekly' },
                { label: 'Month', value: 'monthly' },
                { label: 'Quarter', value: 'quarterly' }
            ],
            loading: false,
            downloadType: 'csv'
        }
    },
    mounted () {
        this.startDate=null
        this.endDate=null
        this.generateReport()
    },
    methods: {
        async generateReport() {
            try {
                this.loading = true
                var responseData=await this.generateReportData(appConstants.SALES_REPORT, this.span, this.startDate, this.endDate, this.startDateCleared, this.endDateCleared)
                this.items = responseData.data
                this.reportStartDate=responseData.startDate
                this.reportEndDate=responseData.endDate
                this.endDateCleared=false
                this.startDateCleared=false
                this.msg=null
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
            } finally {
                this.loading = false
            }
        }
    },
}
</script>

<style scoped>
.report-heading {
    font-size: 1.2rem !important;
    font-weight: 500
}
</style>
